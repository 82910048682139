@import "Colors.sass";
html,
body {
    margin: 0;
    min-height: 100vh;
    overflow: hidden; }

.p16 {
    padding: 16px; }

.p8 {
    padding: 8px; }

.p4 {
    padding: 4px; }

.h100 {
    height: 100%; }

.m0 {
    margin: 0px !important; }

.d-flex {
    display: flex; }
#App {
    height: 100vh;
    .app-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        .content-wrapper {
            display: flex;
            flex: 1;
            justify-content: center;
            max-height: calc(100% - 80px);
            >.content {
                flex: 1;
                min-width: 90vw;
                max-width: 90vw;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
                padding: 0 20px;
                max-height: 100%;
                overflow: auto;

                .pagination-wrapper {
                    display: flex;
                    justify-content: center;
                    padding: 15px 10px; } } } }


    .block {
        padding: 10px;
        margin-bottom: 10px;
        position: relative;
        .title {
            margin: 10px 0;
            display: flex;
            align-items: center;
            svg {
                margin-right: 5px; } } }
    .text-bold {
        font-weight: bold; } }

::-webkit-scrollbar-track {
	// background-color: lighten($primary, 30)
	background-color: lighten($dark, 60);
	border-radius: 10px; }

::-webkit-scrollbar {
	width: 8px;
	height: 8px; }

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: lighten($dark, 50); }
