@import "../../Colors.sass";

.report-wrapper {
  margin-top: 15px !important;

  .MuiToggleButton-root {
    border-color: $primary !important;
    color: $primary !important;
    border-radius: 100px;
    &.Mui-selected {
      background-color: $primary !important;
      color: #ffffff !important;
 } } }      // font-weight: bold
