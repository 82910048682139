$primary: #3B8FE4;
$secondary: #9CF1C7;
$warning: #E4913B;
$text: #444;
$dark: #444;
$alert: #FFA395;
$alert-dark: #E43C3B;
$bg-light: #f3f3f3;
$light: #fff;
$gray: #8D8D8F;
$green: #3BE490;
$orange: #E4913B;
